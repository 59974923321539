import axios, { CreateAxiosDefaults } from 'axios';
import { sentryClient } from 'index';
import { sha256 } from 'js-sha256';
import { appSessionStorage } from 'shared/service';

const SECRET = process.env.REACT_APP_SECRET_KEY;

const defaultHttpClientConfig: CreateAxiosDefaults = {
  baseURL: process.env.REACT_APP_BOBE_API,
  headers: {
    'Content-Type': 'application/json',
    'x-request': SECRET ? sha256.hmac(SECRET, new Date().toUTCString()) : '',
  },
};

export const httpClientAuthorized = axios.create(defaultHttpClientConfig);
export const httpClientUnAuthorized = axios.create(defaultHttpClientConfig);

httpClientAuthorized.interceptors.request.use(
  (config) => {
    const accessToken = appSessionStorage.getToken();
    if (accessToken) {
      config.headers['Authorization'] = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => {
    sentryClient?.captureException(error, {
      captureContext(scope) {
        scope.setExtra('responseData', JSON.stringify(error.response?.data, null, 2));
        return scope;
      },
    });
    return Promise.reject(error);
  },
);
