import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { SUPPORTED_LANGUAGES } from 'shared/constants/common';
import translationEN from 'shared/locales/EN.json';
import translationRU from 'shared/locales/RU.json';

interface II18NProvider {
  children: any;
}

export const languageResources = {
  en: {
    translation: translationEN,
  },
  ru: {
    translation: translationRU,
  },
};

const lang = localStorage.getItem('lang') ?? SUPPORTED_LANGUAGES.RU;

i18n
  .use(initReactI18next)
  .init({
    resources: languageResources,
    fallbackLng: lang,
    interpolation: {
      escapeValue: false,
    },
  })
  .then(() => {
    document.documentElement.setAttribute('lang', i18n.language);
    localStorage.setItem('lang', i18n.language);
  });

i18n.on('languageChanged', (lng) => {
  document.documentElement.setAttribute('lang', lng);
  localStorage.setItem('lang', lng);
});

export const I18NProvider = (props: II18NProvider) => {
  return props.children;
};
