import styles from './style.module.scss';
import { useGameStore, useGlobalStore } from 'shared/store';
import { COMMON_PRELOAD_ASSETS, DEFAULT_FINGER_SLIDER_CONFIG } from 'shared/constants';
import { DonutSize } from 'shared/types';
import { StarsMain } from 'components/lottie';
import { audioManager } from 'shared/service/audio';

export const UserSizeDonut = ({ onGameStart }: { onGameStart: () => void }) => {
  const user = useGlobalStore((state) => state.user);
  const gameReady = useGameStore((state) => state.gameReady);
  const resetGameState = useGameStore((state) => state.resetGameState);

  const fingerSize = (parseInt(user.finger_size || '') ||
    DEFAULT_FINGER_SLIDER_CONFIG.min) as DonutSize;

  const onClick = async () => {
    if (gameReady) {
      // await audioManager.initialize(); // same in session-cooldown-timer
      resetGameState();
      // navigate(ROUTES.SESSION);
      onGameStart();
    }
  };

  return (
    <div>
      <div className={styles.userSizeDonutRoot}>
        <div className={styles['blue-donut-container']}>
          <div className={`${styles['blue-donut-outer-ring']} ${gameReady ? styles.pulse : ''}`}>
            <div className={styles['blue-donut-inner-ring']} onClick={onClick}>
              {/* Стили для новогодней темы */}
              {/* src={COMMON_PRELOAD_ASSETS.donutHome} */}
              <img
                src={`/donuts/blue-donut-${fingerSize}.png` || '/donuts/blue-donut-44.png'}
                alt="blue-donut"
                className={styles['blue-donut']}
                width={120}
                height={120}
              />
              {/* Стили для новогодней темы */}
              {/* style={{ width: '47px', height: '44px', transform: 'translateY(-1px)' }} */}
              <div
                className={styles.donutCenter}
                style={{ width: fingerSize, height: fingerSize }}
              />
            </div>
          </div>
        </div>
        <div className={styles.starsContainer}>
          <StarsMain />
        </div>
      </div>
    </div>
  );
};
