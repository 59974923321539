import { useCloudStorage, useInitData, useWebApp } from '@vkruglikov/react-telegram-web-app';
import { AxiosError } from 'axios';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { acceptInvite, getUser, getSettings, fetchFaq, getRefs, fetchBotInfo } from 'shared/api';
import { useProgress } from 'shared/api/hooks/use-progress';
import { CS_KEYS, ROUTES, SUPPORTED_LANGUAGES } from 'shared/constants';
import { appSessionStorage } from 'shared/service';
import { useGameStore, useGlobalStore, useProgressStore, useReferralsStore } from 'shared/store';
import { handleSettledPromise } from 'shared/utils';

export const useAuthAndFetchAppData = () => {
  const navigate = useNavigate();
  const { getItem } = useCloudStorage();
  const [initDataUnsafe, initData] = useInitData();
  const { i18n } = useTranslation();
  const WebApp = useWebApp();

  const refetchAppData = useGlobalStore((state) => state.refetchAppData);
  const setUser = useGlobalStore((state) => state.setUser);
  const setGlobalLoading = useGlobalStore((state) => state.setGlobalLoading);
  const setFaq = useGlobalStore((state) => state.setFaq);
  const setSettings = useGameStore((state) => state.setSettings);
  const setRefsStore = useReferralsStore((state) => state.setRefsStore);
  const setBotInfo = useGlobalStore((state) => state.setBotInfo);

  const { fetchTasksAndAchievements } = useProgress();

  const fetchUser = async () => {
    try {
      const res = await getUser();
      setUser(res.data);
      return res.data;
    } catch (error) {
      console.error('Error getting user info: ', error);

      if (error instanceof AxiosError && error.response?.status === 403) {
        alert('You have been banned');
        WebApp.close();
        return null;
      }

      navigate(ROUTES.CHOOSE_LANGUAGE, { replace: true });
      return null;
    }
  };

  const setAppLanguage = async (language: SUPPORTED_LANGUAGES | null) => {
    if (language) {
      i18n.changeLanguage(language);
      return;
    }
    const userLanguage = await getItem(CS_KEYS.LANGUAGE);
    if (userLanguage) {
      i18n.changeLanguage(userLanguage as SUPPORTED_LANGUAGES);
      return;
    }
  };

  const fetchAppData = async () => {
    try {
      const [gameSettingsRes, faqRes, refsRes] = await Promise.allSettled([
        getSettings(),
        fetchFaq(),
        getRefs({ level: 1, offset: 0, limit: 0, get_referrer_promo_link: true }),
        fetchTasksAndAchievements(),
      ]);

      handleSettledPromise({
        promise: refsRes,
        callback: (result) => {
          setRefsStore({
            count: result.data.count,
            data: result.data.data,
          });
        },
      });
      handleSettledPromise({
        promise: faqRes,
        callback: (result) => {
          setFaq(result.data);
        },
      });
      handleSettledPromise({
        promise: gameSettingsRes,
        callback: (result) => {
          setSettings(result.data);
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  const checkInvite = async () => {
    try {
      if (!initDataUnsafe?.start_param) return;

      const match = initDataUnsafe?.start_param?.match(/appid_(\d+)/);
      const result = match ? match[1] : null;
      if (!result) return;

      await acceptInvite(Number(result));
    } catch (error) {
      console.log(error);
    }
  };

  const getBotInfo = async () => {
    try {
      const res = await fetchBotInfo();
      setBotInfo({ lang: res.bot });
    } catch (error) {
      console.error('Error getting bot info: ', error);
    }
  };

  useEffect(() => {
    const prepareAppData = async () => {
      try {
        if (initData) {
          appSessionStorage.setToken(initData);
        } else {
          alert('No token');
          return;
        }

        await getBotInfo();

        const user = await fetchUser();
        if (!user) return;

        await setAppLanguage(user.language);
        await fetchAppData();
      } catch (error) {
        console.log(error);
      } finally {
        await checkInvite();
        setGlobalLoading(false);
      }
    };
    prepareAppData();

    //@eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initData, refetchAppData]);
};
