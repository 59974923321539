import styles from './style.module.scss';
import { UITypography } from 'shared/ui';
import { Spinner } from './spinner';
import { BobeHero } from 'components/bobe-hero/bobe-hero';
import { memo } from 'react';
import { COMMON_PRELOAD_ASSETS } from 'shared/constants';
import { useTranslation } from 'react-i18next';

type LoadingContainerProps = {
  isGlobal?: boolean;
  withBg?: boolean;
};

export const LoadingContainer = memo(
  ({ isGlobal = true, withBg = false }: LoadingContainerProps) => {
    const { t } = useTranslation();

    return (
      <div
        className={styles.root}
        style={{
          height: isGlobal ? '100vh' : '100%',
          backgroundImage: withBg ? `url("${COMMON_PRELOAD_ASSETS.appBgImage}")` : 'none',
        }}
      >
        {isGlobal && (
          <>
            <BobeHero />
            <UITypography>{t('common.loading')}...</UITypography>
          </>
        )}

        <Spinner />
      </div>
    );
  },
);
