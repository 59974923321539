import styles from './style.module.scss';
import { useTranslation } from 'react-i18next';
import { COMMON_PRELOAD_ASSETS, TOKEN_SHORTCUT } from 'shared/constants';
import { UITypography } from 'shared/ui';
import { ReactComponent as DollarCircleIcon } from 'assets/icons/dollar-circle.svg';
import { useGlobalStore } from 'shared/store';
import stars from 'assets/images/stars2.png';
import { formatLargeNumber } from 'shared/utils';

export const MainTokenBalance = () => {
  const { t } = useTranslation();
  const user = useGlobalStore((state) => state.user);

  return (
    <div className={styles.root}>
      <UITypography
        iconLeft={<DollarCircleIcon />}
      >{`${t('common.balance')} ${TOKEN_SHORTCUT}`}</UITypography>

      <div className={styles.balanceRoot}>
        <UITypography
          variant="H1"
          component="h2"
        >{`${formatLargeNumber(user.tokens, 8)} ${TOKEN_SHORTCUT}`}</UITypography>
        <img src={COMMON_PRELOAD_ASSETS.bobeBakery} alt="" className={styles.bobe} />
        <img src={stars} alt="" className={styles.stars} />
      </div>
    </div>
  );
};
