import styles from './style.module.scss';
import { UIButton, UITypography } from 'shared/ui';
import { useTranslation } from 'react-i18next';
import { getValuesForTimer } from 'shared/utils';
import { useGameStore } from 'shared/store';
import { useGameCooldownTimer } from 'shared/hooks';
import { TurboBoostModal } from 'components/modals/turbo-boost-modal/turbo-boost-modal';
import { useState } from 'react';
import { audioManager } from 'shared/service/audio';

type DigitSlotProps = {
  value: string;
};

type TimerProps = {
  gameCooldown: number | null;
};

export const SessionCooldownTimer = ({ onGameStart }: { onGameStart: () => void }) => {
  const { t } = useTranslation();

  const [visibleTurboBoostModal, setVisibleTurboBoostModal] = useState(false);
  const gameReady = useGameStore((state) => state.gameReady);
  const booseters = useGameStore((state) => state.booseters);
  const resetGameState = useGameStore((state) => state.resetGameState);

  const { gameCooldown, loading, isDayCooldown } = useGameCooldownTimer();

  const showTimer = gameReady || booseters.isTurboBoostActive;

  const onStartSession = async () => {
    // await audioManager.initialize(); // same in user-size-donut
    resetGameState();
    // navigate(ROUTES.SESSION);
    onGameStart();
  };

  return (
    <>
      <div className={styles['session-timer']}>
        {showTimer ? (
          // TODO: fix bad animations - never use <Swipe> - <Swipe from="right" direction="horizontal" style={{ width: '100%' }} start={!loading}>
          <UIButton
            fullwidth
            onClick={onStartSession}
            style={{ pointerEvents: gameReady ? 'auto' : 'none' }}
          >
            {t('common.play')}
          </UIButton>
        ) : (
          // <Swipe from="right" direction="horizontal" style={{ width: '100%' }} start={!loading}>
          <>
            <div
              style={{
                width: '100%',
                display: 'flex',
                textAlign: 'center',
                alignItems: 'center',
                justifyContent: !isDayCooldown ? 'space-between' : 'center',
                marginBottom: !isDayCooldown ? 8 : 16,
              }}
            >
              <UITypography component="p" fontWeight="bold" style={{ marginBottom: 0 }}>
                {t('main_screen.time_to_next_session')}
              </UITypography>

              {!isDayCooldown && (
                <TurboSpeedButton onClick={() => setVisibleTurboBoostModal(true)} />
              )}
            </div>
            <Timer gameCooldown={gameCooldown} />
          </>
          // </Swipe>
        )}
      </div>

      <TurboBoostModal
        visible={visibleTurboBoostModal}
        onClose={() => setVisibleTurboBoostModal(false)}
      />
    </>
  );
};

const Timer = ({ gameCooldown }: TimerProps) => {
  const { hours, minutes, secondsLeft } = getValuesForTimer(gameCooldown || 0);

  return (
    <div className={styles.digitSlotsContainer}>
      <div className={styles['digit-slot-pair']}>
        <DigitSlot value={hours[0] || '0'} />
        <DigitSlot value={hours[1] || '0'} />
      </div>

      <UITypography variant="H2">:</UITypography>

      <div className={styles['digit-slot-pair']}>
        <DigitSlot value={minutes[0] || '0'} />
        <DigitSlot value={minutes[1] || '0'} />
      </div>

      <UITypography variant="H2">:</UITypography>

      <div className={styles['digit-slot-pair']}>
        <DigitSlot value={secondsLeft[0] || '0'} />
        <DigitSlot value={secondsLeft[1] || '0'} />
      </div>
    </div>
  );
};

const DigitSlot = ({ value }: DigitSlotProps) => {
  return (
    <div className={styles['digit-slot']}>
      <UITypography variant="H2">{value}</UITypography>
    </div>
  );
};

import RocketIcon from 'assets/images/rocket-icon.webp';

const TurboSpeedButton = ({ onClick }: { onClick: () => void }) => {
  const { t } = useTranslation();

  return (
    <button
      className={styles.turboBtn}
      style={{
        backgroundImage: 'url(turbo-speed-btn.webp)',
      }}
      onClick={onClick}
    >
      <img src={RocketIcon} width={24} height={24} />
      <span>{t('common.speed_up')}</span>
    </button>
  );
};
