import flyImage from 'assets/images/fly.png';

export const COMMON_PRELOAD_ASSETS = {
  sessionBgImage: '/session-bg.png',
  defaultBgImage: '/default-bg.png',
  appBgImage: '/main-screen-bg.png',
  bobeHero: '/bobe-hero.webp',
  bobeHero2: '/bobe-hero_market.webp',
  bobeBakery: 'bobe-hero_bak.webp',
  userAvatar: '/bobe-avatar-placeholder.webp',
  fly: '/fly.png',

  // new year
  // defaultBgImage: '/default-bg.png',
  // sessionBgImage: '/new-year/session_background.webp',
  // appBgImage: '/new-year/screens_background.webp',

  // bobeHero: '/new-year/loading.webp',
  // bobeHero2: '/new-year/shop_bobe.webp',
  // bobeBakery: '/new-year/bakery.webp',
  // userAvatar: '/new-year/users_avatar.webp',
  /* Пончик на главной */
  donutHome: '/new-year/donut_home.webp',
  /* Пчела в игровой сессии */
  // fly: '/new-year/fly.webp',
} as const;

export const PRELOAD_GAME_ASSETS = {
  flyImage,
} as const;
